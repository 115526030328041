import { metalPriceToSlug, metalToSlug } from '@core/constant';
import {
  ECurrency,
  ELanguageTags,
  ELiveChartsPeriod,
  EMetal,
  ERouting,
  MetalToSlugMap,
} from '@core/type';

export type ChartChoices = {
  metal: EMetal;
  currency: ECurrency;
  period?: ELiveChartsPeriod;
  language: Exclude<ELanguageTags, ELanguageTags.DEFAULT>;
};

export const buildQueryFromUserChartChoices = ({ metal, currency, language }: ChartChoices) => {
  const metalSlug = metalPriceToSlug[language][metal];
  const currencySlug = currency.toLowerCase();

  return {
    metalSlug,
    slug: [currencySlug],
  };
};

export const getMetalToSlugMap = (keyRoute: ERouting): MetalToSlugMap => {
  if (keyRoute === ERouting.CHARTS) {
    return metalPriceToSlug;
  }

  return metalToSlug;
};
