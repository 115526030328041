import { initialize } from 'launchdarkly-js-client-sdk';
import { useEffect, useState } from 'react';

import { useContextRouting } from '@core/context';
import { Logger } from '@core/logger';

import { useContextMixpanel } from '../context-mixpanel';
import { useEventMixpanel } from './use-event-mixpanel';

export const useEventMixpanelAbTest = (abTestKey: string, isVariant: boolean) => {
  const { enable, isReady } = useContextMixpanel();
  const { keyRoute } = useContextRouting();
  const { trackAbTestEvent } = useEventMixpanel();
  const [isFlagOff, setIsFlagOff] = useState<boolean>(true);

  useEffect(() => {
    if (enable && isReady && abTestKey && !isFlagOff) {
      trackAbTestEvent(abTestKey, isVariant);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [keyRoute, isReady, abTestKey, isVariant, isFlagOff]);

  useEffect(() => {
    getIsFlagOff(abTestKey)
      .then((flagOff) => {
        setIsFlagOff(flagOff);
      })
      .catch((error) => {
        Logger.logError(error);
      });
  }, [isVariant, abTestKey]);
};

async function getIsFlagOff(key: string) {
  const client = initialize(process.env.LAUNCHDARKLY_CLIENT_KEY, {
    kind: 'user',
    key: 'admin',
  });
  await client.waitUntilReady();
  const flags = client.allFlags();
  return flags[key] === null;
}
