import { getImageDimensions } from '@sanity/asset-utils';

import { ELanguage, ERoutingDynamic, MetalToSlugMap } from '@core/type';
import { AlternatesEntity, MetaEntity } from '@core/type/api';
import { MetaSanityResponse, SanityAlternate } from '@core/type/sanity';
import { getLanguageTags } from '@core/util';

import { urlFor } from './sanity-image';

export const formatAlternates = (
  alternates: SanityAlternate[],
  metalToSlugMap: MetalToSlugMap,
): AlternatesEntity => {
  if (!alternates) {
    return null;
  }

  const alternatesFormatted = {} as Record<
    ELanguage,
    { slug: string; alternateType: ERoutingDynamic }[]
  >;
  alternates?.filter(Boolean).forEach(({ slug, language, metalIso }) => {
    alternatesFormatted[language] = [{ slug: slug, alternateType: ERoutingDynamic.SLUG }];
    if (metalIso) {
      alternatesFormatted[language].push({
        slug: metalToSlugMap[getLanguageTags(language)][metalIso],
        alternateType: ERoutingDynamic.METAL_SLUG,
      });
    }
  });

  return alternatesFormatted as AlternatesEntity;
};

export const formatMetas = (metas: MetaSanityResponse): MetaEntity => {
  if (metas) {
    const { og } = metas;

    let height;
    let width;
    let url = '';
    if (og) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      url = og?.asset ? urlFor(og?.asset)?.url() : '';
      if (url) {
        const dimension = getImageDimensions({
          url,
        });
        height = dimension?.height;
        width = dimension?.width;
      }
    }

    return {
      title: metas.title || '',
      description: metas.description || '',
      og: {
        title: og?.title || '',
        description: og?.description || '',
        imageUrl: url,
        imageWidth: width || null,
        imageHeight: height || null,
      },
    };
  } else {
    return null;
  }
};
