import { LiveQueryProvider } from '@sanity/preview-kit';
import { SanityClient } from 'next-sanity';

export default function PreviewProvider({
  children,
  token,
  sanityClient,
}: {
  children: React.ReactNode;
  token: string;
  sanityClient: SanityClient;
}) {
  if (!token) throw new TypeError('Missing token');
  return (
    <LiveQueryProvider client={sanityClient} token={token}>
      {children}
    </LiveQueryProvider>
  );
}
