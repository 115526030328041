import { useEventMixpanelAbTest } from '@mixpanel';
import { useQuery } from '@tanstack/react-query';
import { Dispatch, FC, ReactElement, SetStateAction, Suspense, useEffect, useState } from 'react';

import { SkeletonHomepage, routeSkeleton } from '@core/component';
import {
  useContextAlternate,
  useContextBreadcrumb,
  useContextCurrency,
  useContextMeta,
  useContextRouting,
  useContextUtil,
} from '@core/context';
import { useLanguage } from '@core/hook';
import {
  formatAlternates,
  formatMetas,
  getSanityQuery,
  getVariation,
  mapSanityBreadcrumb,
  sanityClient,
  sanityFetch,
} from '@core/sanity/utils';
import { ECurrency, ELanguageTags, ERouting, PageCMS } from '@core/type';
import { AlternatesEntity, BreadcrumbEntity, EQueryKey } from '@core/type/api';
import { ESanityField, SanityPageResponse } from '@core/type/sanity';
import { getMetalToSlugMap } from '@core/util';

import PreviewProvider from '../../../../core-provider/src/provider/ProviderPreview';
import { ViewNotFound } from '../view';
import { useSetCookiesFromRelevantQueryParams } from './use-set-cookies-from-relevant-query-params';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function ViewSanity<P = any, Q = NodeJS.Dict<string | string[]>, E = any>({
  isLoading,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ViewLoader,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ViewError,
  isError,
  data,
  currency,
  query,
  language,
  propsPage,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ViewPage,
  shouldUseVariation,
}: {
  isLoading?: boolean;
  propsPage?: E;
  ViewLoader?: ReactElement;
  ViewError?: ReactElement;
  isError?: boolean;
  data?: SanityPageResponse | P;
  currency: ECurrency;
  query?: Q;
  language: ELanguageTags;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ViewPage: FC<PageCMS<any, Q, E>>;
  shouldUseVariation?: boolean;
}) {
  if (isLoading || !data) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return ViewLoader;
  }

  if (isError) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return ViewError;
  }

  return (
    <ViewPage
      currency={currency}
      cms={data}
      query={query}
      language={language}
      {...propsPage}
      shouldUseVariation={shouldUseVariation}
    />
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function useCMSPage<P = any, Q = NodeJS.Dict<string | string[]>, E = PageCMS>({
  // eslint-disable-next-line @typescript-eslint/naming-convention
  ViewPage,
  isDraft,
  cmsResult,
  propsPage,
}: {
  ViewPage: FC<PageCMS<P, Q, E>>;
  isDraft?: boolean;
  setDynamicsBreadcrumbs?: (
    props: {
      locale?: Exclude<ELanguageTags, ELanguageTags.DEFAULT>;
      breadcrumbs?: BreadcrumbEntity[];
    } & P,
  ) => BreadcrumbEntity[];
  setDynamicsAlternates?: (
    props: {
      locale?: Exclude<ELanguageTags, ELanguageTags.DEFAULT>;
      alternates?: AlternatesEntity;
    } & P,
  ) => AlternatesEntity;
  withEmptyData?: boolean;
  params?: P;
  haveParamsMapper?: boolean;
  cmsResult?: SanityPageResponse;
  propsPage?: E;
}): ReactElement {
  const { keyRoute, query } = useContextRouting();
  const { currency } = useContextCurrency();
  const { language } = useLanguage();
  const { setBreadcrumbs } = useContextBreadcrumb();
  const { setAlternates } = useContextAlternate();
  const { setMeta } = useContextMeta();
  const { clientIp } = useContextUtil();
  const [finalData, setFinalData] = useState<SanityPageResponse>(cmsResult);

  const { data, isError, isLoading } = useQuery<unknown, unknown, SanityPageResponse>({
    queryKey: [EQueryKey.CMS, keyRoute, query, language],
    queryFn: () =>
      sanityFetch({
        previewDrafts: isDraft,
        query: getSanityQuery(keyRoute, query, language),
      }),
  });

  const abTestKey = data?.abTest?.key;
  const { data: shouldUseVariation } = useQuery<unknown, unknown, boolean>({
    queryKey: [EQueryKey.LAUNCHDARKLY, abTestKey, keyRoute, clientIp],
    queryFn: () => getVariation(abTestKey, clientIp),
    enabled: !!abTestKey && query[ESanityField.AB_TEST] === undefined,
  });

  useEffect(() => {
    setFinalData(null);
    if (data) {
      setAlternates(formatAlternates(data?.alternates?.alt, getMetalToSlugMap(keyRoute)));
      setMeta(formatMetas(data?.[ESanityField.METADATA]));

      if (!abTestKey || !data.abTest.variant) {
        if (data?.body?.breadcrumbs) {
          setBreadcrumbs(mapSanityBreadcrumb(data.body.breadcrumbs, keyRoute));
        }
        setFinalData(data);
      } else if (shouldUseVariation !== undefined) {
        applyAbTest(query, data, setFinalData, shouldUseVariation, keyRoute, setBreadcrumbs);
      }
    }
  }, [data, shouldUseVariation]);

  useSetCookiesFromRelevantQueryParams();
  useEventMixpanelAbTest(abTestKey, shouldUseVariation);

  const children = (
    <ViewSanity
      isLoading={isLoading}
      ViewLoader={routeSkeleton[keyRoute] || <SkeletonHomepage />}
      ViewError={<ViewNotFound />}
      isError={isError}
      data={finalData}
      ViewPage={ViewPage}
      currency={currency}
      query={query as Q}
      language={language}
      propsPage={propsPage}
      shouldUseVariation={
        query[ESanityField.AB_TEST] !== undefined
          ? query[ESanityField.AB_TEST] === 'true'
          : shouldUseVariation
      }
    />
  );

  return (
    <>
      {isDraft ? (
        <Suspense fallback={children}>
          <PreviewProvider token={process.env.SANITY_TOKEN} sanityClient={sanityClient}>
            {children}
          </PreviewProvider>
        </Suspense>
      ) : (
        children
      )}
    </>
  );
}

function applyAbTest(
  query: NodeJS.Dict<string | string[]>,
  data: SanityPageResponse,
  setFinalData: Dispatch<SetStateAction<SanityPageResponse>>,
  shouldUseVariation: boolean,
  keyRoute: ERouting,
  setBreadcrumbs: (breadcrumbs?: BreadcrumbEntity[]) => void,
) {
  if (query[ESanityField.AB_TEST]) {
    setFinalData(
      query[ESanityField.AB_TEST] === 'true'
        ? // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          { ...data, body: data.abTest.variant }
        : data,
    );
  } else if (shouldUseVariation !== undefined) {
    if (shouldUseVariation) {
      setBreadcrumbs(
        data.body.breadcrumbs ? mapSanityBreadcrumb(data.abTest.variant.breadcrumbs, keyRoute) : [],
      );
      setFinalData({
        ...data,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        body: data.abTest.variant,
      });
    } else {
      setBreadcrumbs(
        data.body.breadcrumbs ? mapSanityBreadcrumb(data.body.breadcrumbs, keyRoute) : [],
      );
      setFinalData(data);
    }
  }
}
