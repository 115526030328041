import { SanityImageSource } from '@sanity/asset-utils';
import imageUrlBuilder from '@sanity/image-url';
import { ImageUrlBuilder } from 'sanity';

import { sanityClient } from './sanity-client';

// eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
const builder = imageUrlBuilder(sanityClient);

export function urlFor(source: SanityImageSource): ImageUrlBuilder {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access
  return builder.image(source);
}
