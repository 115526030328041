export type Price = number;

export enum EUnit {
  CELSIUS = 'celsius',
  CENTIMETER = 'centimeter',
  DAY = 'day',
  DEGREE = 'degree',
  GRAM = 'gram',
  OUNCE = 'ounce',
  HOUR = 'hour',
  KILOGRAM = 'kilogram',
  TONNE = 'tonne',
  KILOMETER = 'kilometer',
  KMH = 'kilometer-per-hour',
  LITER = 'liter',
  METER = 'meter',
  MILLIMETER = 'millimeter',
  MINUTE = 'minute',
  MONTH = 'month',
  PERCENT = 'percent',
  SECOND = 'second',
  WEEK = 'week',
  YEAR = 'year',
}

export enum ERoundingMode {
  // src: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat#roundingmode
  CEIL = 'ceil',
  FLOOR = 'floor',
  EXPEND = 'expend',
  TRUNC = 'trunc',
  HALF_CEIL = 'halfCeil',
  HALF_EXPAND = 'halfExpand', // default
  HALF_EVEN = 'halfEven',
}

export type UnitType<T extends Partial<EUnit>> = {
  [key in T]: T;
};
