import { Children, cloneElement, FC, ReactElement } from 'react';

import { FieldError } from '../input/FieldError';
import { Box } from '../layout';
import { ButtonRadioGroupProps } from './interface-button';

export const ButtonRadioGroup: FC<ButtonRadioGroupProps> = ({
  value,
  children,
  flexDirection = 'column',
  flexWrap,
  flex,
  gap,
  ...restProps
}) => {
  if (children) {
    const radioButtonsWithProps = Children.map(children, (child, index) => {
      if (child) {
        return cloneElement(child as ReactElement, {
          isFirst: index === 0,
          currentValue: value,
          ...restProps,
        });
      }
      return null;
    });

    return (
      <>
        <Box display="flex" flexDirection={flexDirection} gap={gap} flexWrap={flexWrap} flex={flex}>
          {radioButtonsWithProps}
        </Box>
        <FieldError {...restProps} />
      </>
    );
  }
  return null;
};
